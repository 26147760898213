import {React} from 'react';
import { Link } from 'react-router-dom';

export const Thumbnail = (props) => {
    const formattedImageURL = "url(" + props.post.thumbnail + ")"
    const backgroundImage = {
        backgroundImage:formattedImageURL
    }
    const post = props.post;
    return (
        parsePostType(post, backgroundImage)
    )
    
}

const parsePostType = (post, backgroundImage) => {
    if (post.type !== "html"){
        
        return(
            <div className="thumbnail-container">
                <div className="thumbnail-title">
                    <p>{post.id + ": " + post.title}</p>
                </div>
                    
                <div className = "thumbnail-image-container">
                    {/* <a  className = "mosaic-thumbnail" 
                        style={backgroundImage} 
                        href={props.imageLink}
                        key={props.id}>{props.title}</a> */}
                    
                    <Link  className = "mosaic-thumbnail" 
                        style={backgroundImage} 
                        to={"/post/" + post.id}
                        key={post.id}/>
                </div>
            </div>
        )
    } else {
        return(
            <div className="thumbnail-container">
                <div className="thumbnail-title">
                    <p>{post.id + ": " + post.title}</p>
                </div>
                    
                <div className = "thumbnail-image-container">
                <a  className = "mosaic-thumbnail" 
                        style={backgroundImage} 
                        href={post.content}
                        key={post.id}>{post.title}</a> 
                </div>
            </div>
        )
    }
}

//parse post type