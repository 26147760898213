import ContentMosaic from './Components/ContentMosaic'

function App(props) {
  return (
    <div className="App">
      <header className="App-header">
        <h1>theo... wtf?</h1>
        <h2>apply wrinkles to brain</h2>
      </header>
      <div>
        <ContentMosaic posts={props.posts} />
      </div>
    </div>
  );
}

export default App;
