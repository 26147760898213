import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './Styles/dist/styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ImagePost} from './Components/PostTypes/ImagePost'
import { VideoPost } from './Components/PostTypes/VideoPost';
// import { HtmlPost } from './Components/PostTypes/HtmlPost';

const posts = require('./jsoncontent2.json')

// import the data here and pass it into app and use it to create routes for ImagePosts
const createPostRoutes = () => {
  let routeArray = []
  for (let p of posts){
    
    //parse post type then add to array with correct element
    switch (p.type){
      case "image":
        // console.log("posted image:" + p.title)
        routeArray.push(
          <Route path={"/post/" + p.id} element = {<ImagePost post={p} key={p.id}/>}/>
        )
        break;
      case "video":
        routeArray.push(
          <Route path={"/post/" + p.id} element = {<VideoPost post={p} key={p.id}/>}/>
        )
        break;
      case "html":
        break
      default:
        console.error('an unsupported post type was found.')
        // console.log(p)
    }
  }
  return routeArray
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<App posts={posts} key="home"/>} />
          {createPostRoutes()}
          {/* <Route path="post/0015" element={<ImagePost />}/> */}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
