import React from "react";
import { Link } from "react-router-dom";

export const ImagePost = (props) => {
    return(
        <div className='post-body'>
            <Link to="/">
                <p style={{color: 'white'}}>Home</p>
            </Link>
            <h1 style={{color: 'white'}}>{props.post.title}</h1>
            <img src={props.post.imageLink} alt={props.post.id} />
            <p style={{color: 'white'}}>{props.post.content}</p>
            <Link to="/">
                <p style={{color: 'white'}}>Home</p>
            </Link>
        </div>
    )
}