import {React} from 'react';
import {Thumbnail} from './Thumbnail';

// const posts = require('../jsoncontent2.json')

const ContentMosaic = (props) => {
    // readYaml()
    return(
        <div className = "mosaic">
            {renderThumbnails(props.posts)}
        </div>
    )
}


const renderThumbnails = (posts) => {
    let allPosts = []
    for(let i of posts){
        // console.log(i)
        // create a route for each post
        // route path=title element=elementTypeComponent(props)
        allPosts.push(
            <Thumbnail 
                post = {i}
                key={i.id} />
        )
    }
    return allPosts
}

export default ContentMosaic