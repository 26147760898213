import React from "react";
import { Link } from "react-router-dom";

export const VideoPost = (props) => {
    return(
        <div className='post-body'>
            <Link to="/">
                <p style={{color: 'white'}}>Home</p>
            </Link>
            <h1 style={{color: 'white'}}>{props.post.title}</h1>
            {/* <video src={props.post.imageLink} type="video/webm" alt={props.post.id} /> */}
            <iframe src={props.post.imageLink} title={props.post.title} width="720" height="720" allow='autoplay' />
            <p style={{color: 'white'}}>{props.post.content}</p>
            <Link to="/">
                <p style={{color: 'white'}}>Home</p>
            </Link>
        </div>
    )
}